var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t(
        "default",
        function() {
          return [
            _c(
              _vm.tableComponent,
              _vm._g(
                _vm._b(
                  {
                    tag: "component",
                    attrs: { bookmarks: _vm.bookmarks, items: _vm.items },
                    on: { "set:bookmark": _vm.setBookmark }
                  },
                  "component",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            )
          ]
        },
        null,
        _vm.context
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }