var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppTab",
    {
      attrs: {
        "title-link-attributes": _vm.mapActive ? { to: _vm.mapPath } : null,
        "title-link-class": { "text-muted": !_vm.mapActive },
        title: "Map"
      },
      on: { click: _vm.onClick }
    },
    [
      _vm.grid
        ? _c("InfoAlert", [
            _vm._v(
              "Please, select 2 or more matches on same map in order to open 2D Map"
            )
          ])
        : _c("InfoAlert", [
            _vm._v(
              "Please, select 2 or more matches of a team on same map in order to open 2D Map"
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }